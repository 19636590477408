import Drawer from '@/components/drawers'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Loading from '@/components/widgets/Loading.vue'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    selectedId:{
      type: String,
    }
  },
  data() {
    return {
      loading: false,
      skeleton: false,
      drawerOpened: false,
      form: {
        rows: null,
        storage: null,
        branch_code: null,
      }
    }
  },
  components: {
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      rows: { required },
      storage: { required },
      branch_code: { }
    }
  },
  watch: {
    async 'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
        this.skeleton = true
        await this.onInitData();
        this.skeleton = false
      }
    }
  },
  methods: {
    onCloseDrawer() {
      this.$v.$reset()
      this.form.rows = null
      this.form.storage = null      
      this.drawerOpened = false

      this.$emit('drawerClose', {
        status: true
      })
    },
    async onSubmitForm() {
      try {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.loading = true
        const formData = new FormData()
        formData.append('warehouse', this.form.storage)
        formData.append('row', this.form.rows)
        formData.append('branch_code', this.form.branch_code ? this.form.branch_code : '')
        await this.$axios.post(this.$host + "/api/v1/branchstock/place/" + this.selectedId, formData,{
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
        this.loading = false
        this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>`+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            showCancelButton: false,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            showCloseButton: true,
        }).then(result => {
          this.onCloseDrawer()
        })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message)
      }
    },
    async onInitData() {
      try {
        this.loading = true
        const res = await this.$axios.get(
          this.$host + "/api/v1/branchstock/" + this.selectedId,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )

        this.form.storage = res.data.branch_warehouse;
        this.form.rows = res.data.branch_row;
        this.form.branch_code = res.data.branch_code;

        this.loading = false
        this.$v.form.$touch()
      } catch (error) {
        this.onExceptionHandler(error.response.data.message);
      }
    },
    resetForm() {
      this.form.storage = null;
      this.form.rows = null;
      this.form.branch_code = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  }
}