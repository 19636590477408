import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    show: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    }
  },
  components: {
    Paginate
  },
  data() {
    return {
      show_price: 1,
      remark: "",
      selectedRows: [],
      busy: false,
      textInput: '',
      answer: 'ค้นหาจาก รหัสคอยล์ ชื่อคอยล์',
      totalItem: 0,
      perPage: 8,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        {
          key: "index",
          label: "#",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "code",
          label: "รหัสสินค้า",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "description",
          label: "รายละเอียด",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "length",
          label: "ความยาว",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "quantity",
          label: "จำนวน",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "unit_name",
          label: "หน่วย",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "total_length",
          label: "ปริมาณรวม",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "unit_price",
          label: "ราคาต่อหน่อย",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "total_price",
          label: "ราคารวม",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        },
        {
          key: "total_send",
          label: "ปริมาณที่จะส่ง",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: false,
        }
      ],
      items: [],
      bill_tax_type: 0,
      bill_discount_amt: 0,
      bill_deposit_amt: 0,
      discount: 0,
      deposit: 0,
      only_product_items: [],
      is_complete_delivery: false,
     }
  },
  computed: {
    rows() {
      return this.items.length
    },
    subtotal() {
      const total = this.selectedRows.reduce((total, obj) => obj.total_price + total,0)
      if (this.bill_tax_type === 2) {
        return (parseFloat(total - this.bill_discount_amt) * 100) / 107;
      } else {
        return total;
      }
    },
    before_vat: function () {
      const total = this.selectedRows.reduce((accumulator, object) => {
        return parseFloat(accumulator) + parseFloat(object.total_price);
      }, 0);
      if (this.bill_tax_type === 2) {
        return (parseFloat(total - this.bill_discount_amt) * 100) / 107;
      } else {
        return total - this.bill_discount_amt;
      }
    },
    vat(){
      const total = this.selectedRows.reduce((total, obj) => obj.total_price + total,0)
      if (this.bill_tax_type === 1) {
        return parseFloat(total - this.bill_discount_amt) * 0.07;
      } else if (this.bill_tax_type === 2) {
        // return (parseFloat(total) * 7) / 107;
        return (parseFloat(total - this.bill_discount_amt) * 7) / 107;
      } else {
        return 0;
      }
    },
    total() {
      const total = this.selectedRows.reduce((total, obj) => obj.total_price + total,0)
      if (this.bill_tax_type === 1) {
        let returnData = parseFloat(total - this.bill_discount_amt) * 1.07;
        returnData -=  this.bill_deposit_amt
        return returnData;
      } else if (this.bill_tax_type === 2) {
        let returnData = parseFloat(total - this.bill_discount_amt);
        returnData -= this.bill_deposit_amt
        return returnData;
      } else {
        const returnData = total - this.bill_discount_amt - this.bill_deposit_amt;
        return returnData;
      }
    }
  },
  watch: {
    show: function (newValue, oldValue) {
      if (newValue) {
        this.bill_tax_type = 0
        this.selectedRows = []
        this.loadItems(this.uuid)
        // this.onSearchHandler(this.currentPage)
      }
    },
    items(new_val) {
      if (new_val) {
        this.only_product_items = this.items.filter(
          (x) => x.type === 1
        )
      }
    },
  },
  methods: {
    onClose () {
      this.$emit('onPopupCloseHandler', {
        status: false
      })
    },
    onSelected() {
      this.$emit('onPopupCloseHandler', {
        status: true,
        uuid: this.uuid,
        show_price: this.show_price,
        remark: this.remark,

        subtotal: this.subtotal,
        discount: this.bill_discount_amt,
        before_vat: this.before_vat,
        vat: this.vat,
        vatable: this.bill_tax_type,
        deposit: this.bill_deposit_amt,
        total: this.total,

        is_complete_delivery: this.is_complete_delivery,

        data: this.selectedRows,
      })
    },
    onRowSelected(items) {
      this.selectedRows = items;
      const selectedItemButOnlyProduct = items.filter(
        (x) => x.type === 1
      )
      const isAllSelected = (JSON.stringify(selectedItemButOnlyProduct) === JSON.stringify(this.only_product_items))
      if(isAllSelected){
        this.bill_deposit_amt = this.deposit
        this.bill_discount_amt = this.discount
        this.is_complete_delivery = true
      }
      else{
        this.bill_deposit_amt = 0
        this.bill_discount_amt = 0
        this.is_complete_delivery = false

      }
    },
    // async onSearchHandler (currentPage) {
      
    //   // this.loading = true
    //   // this.items = []
    //   this.busy = true
    //   this.apiStatus = 'กำลังค้นหาข้อมูล..'
    //   const page = currentPage ? currentPage : 1
    //   const result = await Vue.prototype.$axios.get(`${Vue.prototype.$host}/api/v1/branchstock?page=${page}&perPage=8&keyword=${this.textInput}`)
    //   setTimeout(() => {
    //     // this.loading = false
    //     this.currentData = result.data
    //     this.items = result.data.data
    //     this.totalItem = result.data.total
    //     this.apiStatus = 'ไม่พบข้อมูล.'
    //     this.busy = false
    //     if (result) {
    //       this.items = result.data.data
    //     } else {
    //       this.apiStatus = 'ไม่พบข้อมูล'
    //       this.onExceptionHandler()
    //     }
    //   }, 500)
    // }
    selectAllRows () {
      this.$refs.mytable.selectAllRows()
    },
    clearTableSelected () {
      this.$refs.mytable.clearSelected()
    },
    async loadItems(q) {
      this.loading = true
      this.busy = true;
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/bill-item/${q}`
        );
        if (result) {
          const data = result.data;

          this.bill_tax_type = data.bill_tax_type
          this.discount = data.bill_discount_amt ? data.bill_discount_amt : 0
          this.deposit = data.bill_deposit_amt ? data.bill_deposit_amt : 0

          // data.data.map((item) => {
          //   item.roll = "";
          //   return item;
          // });

          this.items = data.data;
          this.totalItem = data.data.length
        }
        this.loading = false
        this.busy = false;
      } catch (error) {
        this.loading = false
        this.busy = false;
        this.onExceptionHandler(error.response.data.message);
      }
    },
  }
}
