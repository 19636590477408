import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2' 
import axios from 'axios'
import { createRouter } from './router'
import { createStore } from './store'
import { sync } from 'vuex-router-sync'
import 'sweetalert2/dist/sweetalert2.min.css'

require('./mixins/helper')
require('./mixins/thsarabun')

// Vue.prototype.$host = "http://localhost:8000"
Vue.prototype.$host = 'https://sangthai-api.convinapp.com'
Vue.prototype.$client_id = "99ac302a-affc-4edd-9f17-1d0e75bd2c72"
Vue.prototype.$client_secret = "VxAmTNKyNpGov2apwPhqejsaMY73RLI3lpTXr41S"

Vue.prototype.$url= window.location.origin;
// Create a new instance of Axios with a base URL
const api = axios.create({
  baseURL: Vue.prototype.$host,
});
Vue.prototype.$axios = api

Vue.prototype.$rollStatus = [
  { text: "รอเข้า", value: "awaiting" },
  { text: "พร้อมสั่ง", value: "readyfororder" },
  { text: "กำลังส่ง", value: "transferring" },
  { text: "รับแล้ว", value: "delivered" },
  { text: "สาขารับเข้าเอง", value: "selfimported" },
];

// create router and store instances
const router = createRouter()
const store = createStore()

// sync so that route state is available as part of the store
sync(store, router)

Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.config.productionTip = Vue.prototype.$host === "http://localhost:8000" ? true : false
Vue.config.devtools = Vue.prototype.$host === "http://localhost:8000" ? true : false

axios.interceptors.response.use(
  response => {
    if (response.status === 401) {
      // Clear the token from local storage
      localStorage.Sangthai_isLoggedIn = false
      localStorage.Sangthai_access_token = null
      localStorage.Sangthai_expires_in = null
      localStorage.Sangthai_refresh_token = null
      // Redirect the user to the login page
      router.push({ name: 'login' }).catch(error => {})
    }
    return response;
  },
  error => {
    return Promise.reject(error)
  }
)

// Add an interceptor to include authorization headers on all outgoing requests
api.interceptors.request.use(
  (config) => {
  // Check if the user is authenticated and has a token
  const token = localStorage.Sangthai_access_token
  if (token && token !== 'null') {
    // Add the token to the Authorization header
    config.headers.Authorization = `Bearer ${token}`
  } else {
    // Clear the token from local storage
    localStorage.Sangthai_isLoggedIn = false
    localStorage.Sangthai_access_token = null
    localStorage.Sangthai_expires_in = null
    localStorage.Sangthai_refresh_token = null
    // Redirect the user to the login page

    router.push({ path: '/', query: { redirectTo: router.history.current.path }}).catch(error => {})
  }
  return config
})

const app = new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App)
})

app.$mount('#app')