import Account from './account'
import Security from './security'
import Signature from './signature'
import Vue from "vue";

export default {
  name: 'profile',
  components: {
    Account,
    Security,
    Signature
  },
  data() {
    return {
      role: "",
      branch: "",
     }
  },
  async created(){ 
    const s = await this.getRole()
    if(this.role === 'Account executive'){
      localStorage.Sangthai_isLoggedIn = false
      localStorage.Sangthai_access_token = null
      localStorage.Sangthai_expires_in = null
      localStorage.Sangthai_refresh_token = null
      // Redirect the user to the login page
      this.onExceptionHandler('สำหรับทีมขาย กรุณาใช้ระบบ CRM')
      this.$router.push({ name: 'login' }).catch(error => {})
    }
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res?.data.role
      this.branch = res?.data.branch_id
    },
  }
}